<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="8" xl="8" class="img-container mt100">
                <img src="../assets/illustrations/ImageChatImplanté.png" alt="">
                <h2 ><strong> BioPetSensor.com </strong><br>bientôt accessible !</h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.img-container{
    text-align: center;
}

.img-container h2{
    text-align: center;
    align-content: end;
    margin-top: 200px;
}

.img-container strong{
    color: var(--rose);
}

@media (max-width: 960px){
    .img-container h2{
        margin-top: 150px;
    }
}

</style>