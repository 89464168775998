import { createApp } from 'vue';
import App from './App.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Importer les styles de Vuetify
import '@mdi/font/css/materialdesignicons.css'; // Importer les icônes Material Design
import router from './router';

// Importer tous les composants et directives de Vuetify
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
});

createApp(App)
.use(router)
.use(vuetify)
.mount('#app');
